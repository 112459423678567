import type { PopulatedMenu } from 'root/types/menusTypes';
import { AvailabilityType } from '@wix/ambassador-restaurants-menu-settings-v1-menu-ordering-settings/types';
import { DateTime } from 'luxon';
import type {
  MenuAvailability,
  WeeklyAvailability,
} from '@wix/restaurants-olo-operations-client-commons';
import { getWeeklyAvailabilitySummary } from '@wix/restaurants-olo-operations-client-commons';
import type { TFunction } from '@wix/yoshi-flow-editor';
import type {
  AvailabilityStatusKeys,
  ItemModalAvailabilityStatusKeys,
  MenuAvailabilityStatusKeys,
} from 'root/availabilityStatusKeys';
import type { MenuState } from 'root/states/MenusState';

// this method should limit items count on menus according to second parameter
const truncateMenus = (menus: PopulatedMenu[], maxItems: number): PopulatedMenu[] => {
  let itemsLeft = maxItems;
  return menus.map((menu) => {
    return {
      ...menu,
      sections: menu.sections.map((section) => {
        const truncatedSection = { ...section, items: section.items?.slice(0, itemsLeft) };
        itemsLeft -= (section.items || []).length;
        itemsLeft = Math.max(itemsLeft, 0);
        return {
          ...truncatedSection,
          truncated: truncatedSection.items?.length !== (section.items || []).length,
        };
      }),
      size: Math.min(menu.size, maxItems),
      truncated: menu.size > maxItems,
    };
  });
};

const getMenuAvailabilityStatusText = ({
  availability,
  locale,
  timezone,
  keys,
  t,
}: {
  availability: MenuAvailability;
  locale: string;
  timezone: string;
  keys: AvailabilityStatusKeys;
  t: TFunction;
}): {
  shouldCollapseAvailabilityStatus?: boolean;
  text?: string;
} => {
  if (availability.type === AvailabilityType.TIMESTAMP_RANGES) {
    const availableRanges = availability.timestampRangesOptions?.ranges;
    if (availableRanges && availableRanges.length > 0) {
      const now = DateTime.now().setZone(timezone).startOf('day').valueOf();
      const availableRange = availableRanges.find((range) => {
        const endTime = range.endTime;
        return endTime && endTime.valueOf() >= now;
      });

      if (availableRange) {
        const text = formatDateRanges({
          startDate: availableRange.startTime,
          endDate: availableRange.endTime,
          locale,
          timezone,
          keys,
          t,
        });

        return { text };
      } else {
        return { shouldCollapseAvailabilityStatus: true };
      }
    }
  } else if (availability.type === AvailabilityType.WEEKLY_SCHEDULE) {
    const weeklyAvailability = availability.weeklyScheduleOptions?.weeklyAvailability;
    if (weeklyAvailability) {
      const weeklyAvailabilitySummary = getWeeklyAvailabilitySummaryText({
        availability: weeklyAvailability,
        locale,
        t,
      });

      const text = weeklyAvailabilitySummary
        ? t(keys.weeklyScheduleText, { weeklyAvailabilitySummary })
        : undefined;

      return { shouldCollapseAvailabilityStatus: !text, text };
    }
  }

  return { shouldCollapseAvailabilityStatus: true };
};

const formatDateRanges = ({
  startDate,
  endDate,
  locale,
  timezone,
  keys,
  t,
}: {
  startDate?: Date;
  endDate?: Date;
  locale: string;
  timezone: string;
  keys: AvailabilityStatusKeys;
  t: TFunction;
}) => {
  const formattedStartDate = startDate?.toLocaleString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const formattedEndDate = endDate?.toLocaleString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const key = formattedStartDate !== formattedEndDate ? keys.dateRangeText : keys.dateText;

  return t(key, {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });
};

const getWeeklyAvailabilitySummaryText = ({
  availability,
  locale,
  t,
}: {
  availability?: WeeklyAvailability;
  locale: string;
  t: TFunction;
}) => {
  const weeklyAvailabilitySummary =
    availability &&
    getWeeklyAvailabilitySummary({
      availability,
      dayNameProvider: (day) => t(`menu_olo.menuAvailabilityStatus.${day}.short`),
      languageCode: locale,
    });
  if (!weeklyAvailabilitySummary) {
    return '';
  } else {
    const availabilityByDays: { [key: string]: string[] } = {};

    weeklyAvailabilitySummary.forEach((timeSlots, days) => {
      const slots = timeSlots.map((slot) => slot.timeSlot);

      if (slots.length > 0) {
        availabilityByDays[days] = slots;
      }
    });

    return Object.entries(availabilityByDays)
      .map(([days, timeSlots]) => {
        return `${days}: ${timeSlots.join(', ')}`;
      })
      .join('; ');
  }
};

const getAvailabilityStatusProps = ({
  menu,
  locale,
  timezone,
  t,
  keys,
}: {
  menu?: MenuState;
  locale: string;
  timezone: string;
  t: TFunction;
  keys: MenuAvailabilityStatusKeys | ItemModalAvailabilityStatusKeys;
}) => {
  const isMenuOfItemAvailable = menu?.isAvailable ?? true;
  const hasNextAvailability = !!menu?.nextAvailableTimeslot;

  const { text, shouldCollapseAvailabilityStatus } =
    menu?.getAvailabilityStatus({
      locale,
      timezone,
      t,
      keys,
    }) || {};

  return { isMenuOfItemAvailable, hasNextAvailability, text, shouldCollapseAvailabilityStatus };
};

export {
  truncateMenus,
  getMenuAvailabilityStatusText,
  getWeeklyAvailabilitySummaryText,
  getAvailabilityStatusProps,
};
