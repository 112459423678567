import type { ComponentRef, WidgetInstallationType } from '@wix/platform-editor-sdk';
import type { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';
import type { IBIReporterService } from 'root/services/biReporterService';
import { LIGHTBOX_CONNECTED_PARAMS } from 'root/appConsts/consts';
import type { FedopsLogger } from 'root/utils/monitoring/FedopsLogger';

const TOKEN = 'token';
type presetIds = {
  layout: string;
  style: string;
};
type presetBreakpoints = 'desktop' | 'tablet' | 'mobile';

const widgetLayouts = {
  containerLayout: {},
  spx: {
    refWidth: 1200,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'auto',
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
  },
  itemLayout: {
    alignSelf: 'center',
    margins: {
      left: {
        type: 'percentage',
        value: 0,
      },
      right: {
        type: 'percentage',
        value: 0,
      },
      top: {
        type: 'percentage',
        value: 0,
      },
      bottom: {
        type: 'percentage',
        value: 0,
      },
    },
    gridArea: {
      rowStart: 1,
      rowEnd: 2,
      columnStart: 1,
      columnEnd: 2,
    },
    justifySelf: 'center',
    type: 'GridItemLayout',
  },
  type: 'SingleLayoutData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

const lightboxLayouts = {
  containerLayout: {
    type: 'GridContainerLayout',
    rows: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    columns: [
      {
        type: 'fr',
        value: 1,
      },
    ],
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  spx: {
    refWidth: 1200,
    resolverType: 'scale',
  },
  componentLayout: {
    hidden: false,
    height: {
      type: 'percentage',
      value: 100,
    },
    type: 'ComponentLayout',
    width: {
      type: 'percentage',
      value: 100,
    },
    maxWidth: {
      type: 'px',
      value: 700,
    },
  },
  itemLayout: {
    type: 'FixedItemLayout',
    margins: {
      left: {
        type: 'px',
        value: 0,
      },
      right: {
        type: 'px',
        value: 0,
      },
      top: {
        type: 'px',
        value: 0,
      },
      bottom: {
        type: 'px',
        value: 0,
      },
    },
    justifySelf: 'center',
    alignSelf: 'center',
  },
  type: 'SingleLayoutData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

export const installLightbox = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  widgetId: string,
  title: string,
  tpaPageId: string,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  fedopsLogger: FedopsLogger,
  isFirstInstall: boolean,
  width = 700
) => {
  fedopsLogger.installLightboxStarted();
  const popupRef = await editorSDK.document.pages.popupPages.addConnected(TOKEN, {
    title,
    shouldNavigateToPage: isResponsive,
    controllerType: `${LIGHTBOX_CONNECTED_PARAMS.CONTROLLER}_${tpaPageId}`,
    popupRole: `${LIGHTBOX_CONNECTED_PARAMS.ROLE}_${tpaPageId}`,
    definition: {
      data: {
        // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
        // @ts-ignore
        appDefinitionId: appDefId,
        managingAppDefId: APP_DEF_IDS.restaurants,
        hidePage: false,
        tpaPageId,
        pageBackgrounds: {
          desktop: {
            custom: true,
            isPreset: false,
            ref: {
              type: 'BackgroundMedia',
              color: '#000',
              // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
              // @ts-ignore
              colorOpacity: 0.6,
              alignType: 'center',
              fittingType: 'fill',
              scrollType: 'none',
              colorOverlay: '',
              colorOverlayOpacity: 0,
            },
          },
          mobile: { custom: false, isPreset: false },
        },
      },
      components: [
        {
          type: 'Container',
          id: widgetId,
          skin: 'wysiwyg.viewer.skins.area.RectangleArea',
          layout: {
            height: 320,
            width,
            x: 0,
            y: 0,
          },
          layouts: isResponsive ? lightboxLayouts : undefined,
          componentType: 'wysiwyg.viewer.components.PopupContainer',
          props: {
            type: 'PopupContainerProperties',
          },
        },
      ],
      style: {
        type: 'ComponentStyle',
        style: {
          properties: {
            'alpha-bg': '0.6',
            bg: 'color_15',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            bg: 'theme',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Page',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.page.ResponsivePageWithColorBG',
        pageId: '',
        compId: '',
      },
    },
  });

  const [lightboxComponent] = await editorSDK.document.components.getChildren(TOKEN, {
    componentRef: popupRef,
  });
  const lightboxLayout = await editorSDK.document.components.layout.get(TOKEN, {
    componentRef: popupRef,
  });

  const msid = await editorSDK?.info.getMetaSiteId('_token');

  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'lightbox_installation',
    msid,
    lightboxTitle: title,
    lightboxWidgetId: lightboxComponent?.id,
    lightboxId: popupRef?.id,
    lightboxLayout: JSON.stringify(lightboxLayout),
    isFirstInstall,
  });

  fedopsLogger.installLightboxEnded();
  return lightboxComponent ?? popupRef;
};

export const addWidgetToLightbox = async (
  editorSDK: FlowEditorSDK,
  lightboxRef: ComponentRef,
  widgetId: string,
  scopedPresets: Partial<Record<presetBreakpoints, presetIds>>,
  isResponsive: boolean,
  biReporterService: IBIReporterService,
  width = 700
) => {
  const componentRef = await editorSDK.application.appStudioWidgets.addWidget(TOKEN, {
    widgetId,
    installationType: 'closed' as WidgetInstallationType,
    layout: {
      fixedPosition: false,
      // NOTE: docked is being used to stretch the widget to the full width of the page
      // @ts-expect-error
      docked: {
        left: {
          px: 0,
          vw: 0,
        },
        right: {
          px: 0,
          vw: 0,
        },
      },
      height: 320,
      width,
      x: 0,
      y: 0,
    },
    layouts: isResponsive ? widgetLayouts : undefined,
    scopedPresets,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    containerRef: lightboxRef as any,
  });

  const lightBoxWidgetLayout = await editorSDK.document.components.layout.get(TOKEN, {
    componentRef,
  });

  const msid = await editorSDK?.info.getMetaSiteId('_token');
  biReporterService?.reportOloEditorInstallationStepsEvent({
    step: 'add_widget_to_lightbox',
    msid,
    lightboxWidgetId: widgetId,
    lightboxParentRef: lightboxRef?.id,
    lightboxLayout: JSON.stringify(lightBoxWidgetLayout),
  });
};
